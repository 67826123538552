<template>
  <div>
    <navBar :title="title"></navBar>

    <div class="bill-form" v-if="status">
      <ul class="form">
        <li>
          <span>*企业名称:</span>
          <input placeholder="请输入企业名称" type="text" v-model="info.name" />
        </li>
        <li>
          <span>*信用代码:</span>
          <input
            @input="inputs(info.creditCode)"
            placeholder="请输入信用代码"
            type="text"
            v-model="info.creditCode"
          />
        </li>
        <li>
          <span>注册地址:</span>
          <input placeholder="请输入注册地址" type="text" v-model="info.registerAddress" />
        </li>
        <li>
          <span>注册电话:</span>
          <input placeholder="请输入注册电话" type="text" v-model="info.phone" />
        </li>
        <li>
          <span>开户银行:</span>
          <input placeholder="请输入开户银行" type="text" v-model="info.accountOpening" />
        </li>
        <li>
          <span>银行账号:</span>
          <input
            @input="bankAccountInput(info.bankAccount)"
            placeholder="请输入银行账号"
            type="text"
            v-model="info.bankAccount"
          />
        </li>
      </ul>
      <div class="confirm">
        <van-button @click="billConfirm" type="info">确认修改</van-button>
      </div>
    </div>

    <div class="mail-form" v-else>
      <ul class="form">
        <li>
          <span>店名:</span>
          <input placeholder="请输入店名" type="text" v-model="info.companyName" />
        </li>
        <li>
          <span>*收件地址:</span>
          <input placeholder="请输入收件地址" type="text" v-model="info.address" />
        </li>
        <li>
          <span>*收件人:</span>
          <input placeholder="请输入收件人" type="text" v-model="info.name" />
        </li>
        <li>
          <span>*收件电话:</span>
          <input placeholder="请输入收件电话" type="text" v-model="info.phone" />
        </li>
      </ul>
      <div class="confirm">
        <van-button @click="mailConfirm" type="info">确认修改</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '../navBar'
import { Toast } from 'vant';
import { Dialog } from 'vant';

export default {
  name: '',
  components: {
    navBar
  },
  data () {
    return {
      title: {
        name: '',
        status: true
      },
      msg: '',
      info: {},
      status: null,//判断出现发票或邮寄页面
    }
  },
  created () {
    this.info = this.$route.params
    if ('accountOpening' in this.$route.params) {
      this.title.name = "修改发票信息"
      this.status = true
    } else {
      this.title.name = "修改邮寄信息"
      this.status = false

    }
  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 发票信息格式化
    inputs (str) {
      this.info.creditCode = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ').toUpperCase();
    },
    // 银行卡号格式化
    bankAccountInput (str) {
      this.info.bankAccount = str.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ');
    },
    // 修改发票信息
    billConfirm () {
      if (this.info.name !== '' && this.info.creditCode !== '') {
        Dialog.confirm({
          title: '提示',
          message: '确认修改?',
        })
          .then(() => {
            this.$request.put(this.$api.changeInfo, this.info).then(res => {
              Toast.success('修改成功')
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            })
          })
      } else {
        Toast.fail('请输入必填项')
      }
    },
    // 修改邮寄信息
    mailConfirm () {
      if (this.info.address != '' && this.info.name != '' && this.info.phone != '') {
        Dialog.confirm({
          title: '提示',
          message: '确认修改?',
        })
          .then(() => {
            console.log('1');
            this.$request.put(this.$api.changeAddress, this.info).then(res => {
              Toast.success('修改成功')
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            })
          })
      } else {
        Toast.fail('请输入必填项')
      }
    }

  }
}
</script>

<style lang="less" scoped>
.form {
  li {
    border-bottom: 1px solid #ccc;
    height: 36px;
    display: flex;
    align-items: center;

    padding-left: 20px;

    &:nth-child(1) {
      padding-left: 13px;
    }
    &:nth-child(2) {
      padding-left: 13px;
    }
    font-size: 14px;
    input {
      border: none;
      width: 70%;
      margin-left: 10px;
    }
  }
}

.mail-form {
  .form {
    li {
      border-bottom: 1px solid #ccc;
      height: 36px;
      display: flex;
      align-items: center;

      padding-left: 20px;

      span {
        display: inline-block;
        text-align: right;
        width: 20%;
      }
      input {
        width: 70%;
        margin-left: 10px;
      }
    }
  }
}

.confirm {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
